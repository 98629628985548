import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../images/logo.svg'; // Adjust the path as needed
import '../css/main.css'; // Adjust the path as needed
import '../css/style.css'; // Adjust the path as needed
import '../css/wow.css'; // Adjust the path as needed
import '../css/reposonsive.css'; // Adjust the path as needed
import Swal from 'sweetalert2';
import WithToast from '../commonjs/withtoast';
import { logoutallapi } from '../commonjs/url';
import axiosInstance from '../commonjs/axiosinstand';
import { handleApiError } from '../commonjs/commonfunctions';
import { devandliveurl } from '../commonjs/url';
import { publicDomain } from '../commonjs/url';
const logoutapiurl = logoutallapi();
const devurl = devandliveurl();
const URLS = {
    HOME: `${devurl}/index.php`,
    BOOK_APPOINTMENT: `${devurl}/appointment.php`,
    ABOUT_US: `${devurl}/aboutus.php`,
    CONTACT_US: `${devurl}/contactus.php`,
    SIGNUP: '/signup',
    LOGIN: '/login',
};
const Header = ({showErrorToast,userProfile,userAccounts }) => {
    const navigate = useNavigate();
    const location = useLocation();
    // const [userProfile,setUserprofile] = useState([]);
    // const dispatch = useDispatch();
    const [isLoading4, setIsLoading4] = useState(false);
    // // const userProfile = useSelector((state) => state.userProfile.userProfile);
    // const userAccounts = useCallback(async () => {
    //     try {
    //       const res = await sid();
    //       setUserprofile(res.data?.data || null);
    //     } catch (error) {
    //       handleApiError(error, null, null, true, showErrorToast);
    //     }
    //   }, []);
      
     
    //  useEffect(() => {
    //    userAccounts();
    //  }, []);

    const handleLogout = async () => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to log out?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, log out!',
            cancelButtonText: 'Cancel'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const data = {
                        authuser: '0'
                    };
                    setIsLoading4(true); // Start loading before making the API request
                    const response = await axiosInstance.post(logoutapiurl, data);
    
                    if (response.status === 200) {
                        // Fetch the user profile and wait for it to complete
                        // await dispatch(fetchUserProfile(showErrorToast));
                        await userAccounts();
    
                        // After fetchUserProfile is finished, stop the loading and navigate to the login page
                        setIsLoading4(false);
                        navigate('/login');
                    } else {
                        setIsLoading4(false); // Stop loading on failure
                        Swal.fire({
                            icon: 'error',
                            title: 'Logout Failed',
                            text: 'Something went wrong. Please try again later.',
                        });
                    }
                } catch (error) {
                    setIsLoading4(false); // Stop loading in case of error
                    handleApiError(error, null, null, true, showErrorToast);
                }
            }
        });
    };
    
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const handleBurgerMenuClick = () => {
        if (isMenuOpen) {
            document.body.classList.remove('mobile_menu_open');
        } else {
            document.body.classList.add('mobile_menu_open');
        }
        setIsMenuOpen(!isMenuOpen);
    };
    // Determine if the current pathname matches
    const isActive = (path) => location.pathname === path;

    return (
        <>
            {isLoading4 && (
                <>
                    <div className="loaderover">
                        <div className="loader"></div>
                    </div>
                </>
            )}
            <div className="content_wrapper">
                <header className="header">
                    <div className="c">
                        <div className="row">
                            <div className="col col_4">
                                <Link to="/" className="logo">
                                    <img src={logo} alt="Logo" />
                                </Link>
                            </div>
                            <div className="col col_75">
                                <div className="header_menu">
                                <ul>
                                        <li className={isActive(URLS.HOME) ? 'active' : ''}>
                                            <Link to={URLS.HOME}>Home</Link>
                                        </li>
                                        <li className={isActive(URLS.BOOK_APPOINTMENT)}>
                                            <Link to={URLS.BOOK_APPOINTMENT}>Book Appointment</Link>
                                        </li>
                                        <li className={isActive(URLS.ABOUT_US) ? 'active' : ''}>
                                            <Link to={URLS.ABOUT_US}>About us</Link>
                                        </li>
                                        {Array.isArray(userProfile) && userProfile.length > 0 ? (
                                            <li style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={`${publicDomain()}core/${userProfile[0]?.image}`} alt='profile' className='headerimage' />
                                                <p className='elipse' style={{ color: '#FFF' }}>{userProfile[0]?.name}</p>
                                            </li>
                                        ) : (
                                            <li className={isActive(URLS.SIGNUP) ? 'active' : ''}>
                                                <Link to={URLS.SIGNUP}>Sign in</Link>
                                            </li>
                                        )}
                                        {Array.isArray(userProfile) && userProfile.length > 0  && (
                                            <li>
                                                <p style={{ color: "#FFF", cursor: 'pointer' }} onClick={handleLogout}>Signout</p>
                                            </li>
                                        )}
                                        <li className={isActive(URLS.CONTACT_US) ? 'active' : ''}>
                                            <Link to={`${URLS.CONTACT_US}`}>Contact us</Link>
                                        </li>
                                    </ul>
                                    <i className="burger_menu" onClick={handleBurgerMenuClick}>
                                        <span className="lines"></span>
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className='mobile_menu'>
                    <div className="c">
                        <div className="row">
                            <div className="col col_4">
                                <Link to="/" className="logo">
                                    <img src={logo} alt="Logo" />
                                </Link>
                            </div>
                            <div className="col ">
                                <div className="header_menu">
                                    <ul>
                                        <li className={isActive(URLS.HOME) ? 'active' : ''}>
                                            <Link to={URLS.HOME}>Home</Link>
                                        </li>
                                        <li className={isActive(URLS.BOOK_APPOINTMENT) ? 'active' : ''}>
                                            <Link to={URLS.BOOK_APPOINTMENT}>Book Appointment</Link>
                                        </li>
                                        <li className={isActive(URLS.ABOUT_US) ? 'active' : ''}>
                                            <Link to={URLS.ABOUT_US}>About us</Link>
                                        </li>
                                        {Array.isArray(userProfile) && userProfile.length > 0  ? (
                                            <li style={{ display: 'flex', alignItems: 'center' }}>
                                                <img src={`${publicDomain()}core/${userProfile[0]?.image}`} alt='profile' className='headerimage' />
                                                <p style={{ color: '#FFF' }} className='elipse'>{userProfile[0].name}</p>
                                            </li>
                                        ) : (
                                            <li className={isActive(URLS.SIGNUP) ? 'active' : ''}>
                                                <Link to={URLS.SIGNUP}>Sign in</Link>
                                            </li>
                                        )}
                                        {Array.isArray(userProfile) && userProfile.length > 0  && (
                                            <li>
                                                <p style={{ color: "#FFF", cursor: 'pointer' }} onClick={handleLogout}>Logout</p>
                                            </li>
                                        )}
                                        <li className={isActive(URLS.CONTACT_US) ? 'active' : ''}>
                                            <Link to={URLS.CONTACT_US}>Contact us</Link>
                                        </li>
                                    </ul>
                                    <i className="burger_menu" onClick={handleBurgerMenuClick}>
                                        <span className="lines"></span>
                                    </i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default WithToast(Header);
