import React,{useEffect,useCallback,useState} from 'react'
import Header from './header'
import Footer from './footer'
import geroup from '../images/Group 11565.svg';
import appstore from '../images/app store-02.svg';
import playstore from '../images/app store-03.svg';
import { useNavigate } from 'react-router-dom';
// import { useSelector, useDispatch } from 'react-redux';
// import { fetchUserProfile } from '../commonjs/redux/action';
import WithToast from '../commonjs/withtoast';
import { sid } from '../commonjs/apiurlhelper';
import {  handleApiError } from '../commonjs/commonfunctions';
function Playstore({showErrorToast}) {
    const [userProfile,setUserprofile] = useState([]);
    const navigate = useNavigate();
    const userAccounts = useCallback(async () => {
                   try {
                     const res = await sid();
                     if(res.status === 200){
                        if(res.data.data.length > 0){
                          setUserprofile(res.data?.data || null);
                        }else{
                            navigate('/login');
                        }
                     }
                   } catch (error) {
                     handleApiError(error, null, null, true, showErrorToast);
                   }
                 }, []);
                useEffect(()=>{
                    userAccounts(); 
                },[]); 
    return (
        <>
            <Header userAccounts={userAccounts} userProfile={userProfile}/>
            <div className='playstore_appstore root_work'>
                <div className='c'>
                <div className='row ma_top_0'>
                <div className='col col_2'>
                <div className='playstore_appstore'>
                 <img src={geroup} alt='group' className='playsuccessimage'></img>
                 </div>   
                </div>
                <div className='col col_2'>
                <div className='playstore_appstore'>
                {Array.isArray(userProfile) && userProfile.length > 0  && (
  <p className="playsuccessimagep">
    <span>Congratulations!</span> {userProfile[0]?.name} on logging into your account!
  </p>
)}

                  <p className='playsuccessimagep'>Enjoy benefits by downloading
                  Our <span>ROOTS</span> app.</p>
                  <div className='col col_2'>
                    <p className='playsuccessimagep d_fle'><img src={playstore} alt='playstore' className='appstoreimage'></img>Play Store</p>
                   </div> 
                    <div className='col col_2'>  
                    <p className='playsuccessimagep d_fle'><img src={appstore} alt='appstore' className='appstoreimage'></img>App Store</p>
                  </div>
                 </div>   
                </div>
                </div>    
                </div>
            </div>
            <Footer />
        </>
    )
}

export default WithToast(Playstore)