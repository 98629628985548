import React,{memo, useState,useCallback,useEffect} from 'react';
import { forgotpasswordotpverifyapi, resendotpapi } from '../../commonjs/url';
import { handleApiError } from '../../commonjs/commonfunctions';
import axiosInstance from '../../commonjs/axiosinstand';
import { useNavigate } from 'react-router-dom';
const forgotpasswordotpverifyapiurl = forgotpasswordotpverifyapi();
const Forgototppage = memo(({formValues,setIsLoading4,setShowforgotpage,setShowforgotpageotp,setShowforgotpagepassword,showErrorToast}) => {
    const [otpState, setOtpState] = useState({
        otp: '',
        countdown: 60, 
    });
    const navigate = useNavigate();
    const resendOTPapiurl = resendotpapi();
    const [error422,setError422] = useState('');
    const [isenabled,setEnabled] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setOtpState((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
        setError422('');
    }
    const handleKeyPress = (e) => {
        // Allow only numeric characters (0-9) and some specific control keys
        const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Home', 'End'];
      
        if (!/^\d$/.test(e.key) && !allowedKeys.includes(e.key)) {
          e.preventDefault();
        }
      };
    const check = useCallback(()=>{
        const valid = (
          otpState.otp !== ''
        )
        setEnabled(valid);
      },[otpState.otp]);
      useEffect(()=>{
          check();
      },[check]);
    const cancel = () => {
        setShowforgotpage(true);
        setShowforgotpageotp(false);
        setShowforgotpagepassword(false);
    }
    const resend = async() => {
      const data = {
        verify : 'email'
       }
      try {
          setIsLoading4(true);
          const response = await axiosInstance.post(resendOTPapiurl, data);
          if (response.status === 200) {
              setIsLoading4(false);
              setOtpState((prev)=>({
                ...prev,otp:''
              }))
              setError422('');
          }
      }
      catch (error) {
        handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
      }finally{
        setIsLoading4(false);
      }
    }
    const otpverify = async() => {
        const data = {
            otp:otpState.otp
         }
         try{
             setIsLoading4(true);
            const response = await axiosInstance.post(forgotpasswordotpverifyapiurl,data);
            if(response.status === 200){
             setShowforgotpage(false);
             setShowforgotpageotp(false);
             setShowforgotpagepassword(true);
             setIsLoading4(false);
            }
         }catch(error){
           if(error.response){
             if(error.response.status === 422){
              const error_code = error.response.data.code;
              if (error_code === 'otp_retry') {
                navigate('/');
                setIsLoading4(false);
              }else{
                handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
              }
             } else{
              handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
             }
          }else{
          handleApiError(error, setIsLoading4, setError422, false, showErrorToast);
          }
         }
    }
  return (
    <>
    <h6 className='forgot_password_h6'>Verification</h6>
    <p className='forget_p'>{`Enter your verification code that you received on your ${formValues.forgotvalueselect === 'Email' ? "Email" : 'Mobile Number'}.`}</p>
    <div className="forgot_row">
    <div className="col col_1">    
    <div className="form_group">
    <label className='forgot_label'>Verification Code</label>
    <input type="text" name="otp" maxLength={6} onKeyPress={handleKeyPress} className={`form_controls logininputsign ${otpState.otp ? 'logininputsign-active' : '' }`}  value={otpState.otp} onChange={handleChange}/>   
    </div>
    </div>
    {error422 && (<div className='errors'>{error422}</div>)} 
    <div className="col col_1">
    <div className='forgot_button_div'>
    <button className='forgot_cancel' onClick={cancel}>Cancel</button>
    <button className={`forgot_send_code ${isenabled ? 'forgot_send_code_active' : 'forgot_send_code_disabled'}`} disabled={!isenabled} onClick={otpverify} >Verify</button>
    </div>
    </div>
    <p className='forget_p'>If you didn’t receive a code? <span className='forget_resend_span' onClick={resend}>Resend</span></p>
    </div>
    </>
  )
})

export default Forgototppage;