import React,{useState,useRef,useEffect,useCallback} from 'react'
import { Link } from 'react-router-dom';
import axiosInstance from '../commonjs/axiosinstand';
import { country_codeapi ,loginapi} from '../commonjs/url';
import blueeye from '../images/Icon.svg';
import blueeyeslash from '../images/Icon (1).svg'
import { useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import { handleApiError, process_params,} from '../commonjs/commonfunctions';
import WithToast from '../commonjs/withtoast';
const country_codeapiurl = country_codeapi();
const loginUrl = loginapi();
function Loginpage({setIsSignUp,showSuccessToast,showErrorToast,setIsLoading4}) {
    const navigate = useNavigate();
    const mobileInputRef = useRef(null);
    const inputref = useRef(null);
    const [error,setError]=useState({usernameerror:'',passworderror:''});
    const [error422,setError422]=useState('');
    // const { formValues, fetchCountryCode,handleCountryChange } = useFetchCountryCode(showErrorToast, mobileInputRef,setError422);
    const [formValues, setFormValues] = useState({isLoading3: false,countryOptions: [],mcc_id: null, dial_code: '',country_code: '', username:'',password:'',showcountryinput:false,isCountryCodeFetched:false,passwordshow:false, });
    // const [formValues1,setFormValues1]=useState({
              
    // });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevState) => ({
          ...prevState,
          [name]: value,
        }));
        setError((prev)=>({
          ...prev,usernameerror:'',passworderror:''
        }))
    }
    const passwordvisiblity = () => {
      setFormValues((prev)=>({
          ...prev,passwordshow:!prev.passwordshow
      }))
  }
    const fetchCountryCode = useCallback(async () => {
        setFormValues((prevState) => ({
          ...prevState,
          isLoading3: true,
        }));
    
        try {
          const response = await axiosInstance.get(country_codeapiurl); // Update with the correct URL
          const data = response.data;
    
          if (data.message === 'Success') {
            const mccList = data.data;
            const codes = mccList.map((mcc) => ({
              country_code: mcc.country_code,
              dial_code: mcc.code,
              mcc_id: mcc.id,
              label: `${mcc.name} (+${mcc.code})`,
              value: mcc.country_code,
              flag: `https://flagcdn.com/w320/${mcc.country_code.toLowerCase()}.png`,
            }));
    
            setFormValues((prevState) => ({
              ...prevState,
              countryOptions: codes,
            }));
    
            const selectedCountryData = codes.find(
              (country) => country.country_code === 'US'
            );
    
            if (selectedCountryData) {
              setFormValues((prevState) => ({
                ...prevState,
                mcc_id: selectedCountryData.mcc_id,
                dial_code: `+${selectedCountryData.dial_code}`,
                country_code: selectedCountryData.country_code,
              }));
    
              const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
              if (maxLength !== null && mobileInputRef.current) {
                mobileInputRef.current.setAttribute('maxlength', maxLength);
              }
            }
          } else {
            console.error('Failed to fetch country codes');
          }
        } catch (error) {
          handleApiError(error, null, null, true, showErrorToast);
        } finally {
          setFormValues((prevState) => ({
            ...prevState,
            isLoading3: false,
          }));
        }
      }, [showErrorToast, mobileInputRef]);
      const handleCountryChange = (selectedOption) => {
        const selectedCountryData = formValues.countryOptions.find(
          (country) => country.value === selectedOption.value
        );
    
        if (selectedCountryData) {
            setFormValues((prevState) => ({
                ...prevState,
                country_code: selectedCountryData.country_code,
                dial_code: `+${selectedCountryData.dial_code}`,
                mcc_id: selectedCountryData.mcc_id
            }));
         
          const maxLength = updateExampleNumber(selectedCountryData.country_code.toLowerCase());
          if (maxLength !== null && mobileInputRef.current) {
            mobileInputRef.current.setAttribute('maxlength', maxLength);
          }
        }
        setError422('');
      };
      const updateExampleNumber = (iso2) => {
        if (window.intlTelInputUtils) {
          try {
            const exampleNumber = window.intlTelInputUtils.getExampleNumber(
              iso2,
              true,
              window.intlTelInputUtils.numberFormat.E164
            );
            const cleanedNumber = exampleNumber.replace(/\D/g, '').replace(/^0+/, '');
            return cleanedNumber.length;
          } catch (error) {
            console.error('Error fetching example number for country code:', iso2, error);
            return 15; // Fallback to a default length
          }
        } else {
          console.error('intlTelInputUtils is not loaded');
          return 15; // Fallback to a default length
        }
      };
    useEffect(() => {
        if (formValues.showcountryinput) {
            mobileInputRef.current?.focus();
        } else {
           inputref.current?.focus();
        }
    }, [formValues.showcountryinput]);
    const handleUsernameChange = (e) => {
        let inputUsername = e.target.value.replace(/\s/g, '');
        if (inputUsername.startsWith('0')) {
            inputUsername = inputUsername.slice(1);
        }
        if (/^\+?\d+$/.test(inputUsername)) {
            if (!formValues.isCountryCodeFetched) {
                setFormValues((prevstate)=>({
                    ...prevstate,showcountryinput:true
                }))
                if (mobileInputRef.current) {
                  const maxLength = mobileInputRef.current ? mobileInputRef.current.getAttribute('maxlength') : 15;
                    if (inputUsername.length > maxLength) {
                        inputUsername = inputUsername.slice(0, maxLength);
                    }
                }
                fetchCountryCode();
                setFormValues((prevstate)=>({
                    ...prevstate,isCountryCodeFetched:true
                }))
            }
        } else {
            setFormValues((prevstate)=>({
                ...prevstate,showcountryinput:false,isCountryCodeFetched:false
            }))
        }
        setFormValues((prevstate)=>({
            ...prevstate,username:inputUsername
        }))
        setError((prev)=>({
          ...prev,usernameerror:'',passworderror:''
        }))
        setError422('');
      };
      const handlePaste = (e) => {
        const pastedText = e.clipboardData.getData('text');
        let numericPastedText = pastedText.replace(/[^0-9]/g, '');
  
        // Remove leading '0' if present
        if (numericPastedText.startsWith('0')) {
            numericPastedText = numericPastedText.slice(1);
        }
  
        const maxLength = mobileInputRef.current ? mobileInputRef.current.getAttribute('maxlength') : 15;
        if (numericPastedText.length > maxLength) {
            numericPastedText = numericPastedText.slice(0, maxLength);
        }
        setFormValues((prevstate)=>({
            ...prevstate,username:numericPastedText
        }))
        setError422('');
        e.preventDefault();
      };
      const customSingleValue = ({ data }) => (
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <img
            src={data.flag}
            alt=""
            style={{ width: '25px', height: 'auto', marginLeft: '10px' }}
          />
          {/* <p className="customsinglep">{`+${data.dial_code}`}</p> */}
        </div>
      );
      const customOption = (props) => {
        return (
          <components.Option {...props}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                src={props.data.flag}
                alt=""
                style={{ width: '25px', height: 'auto', marginRight: '10px' }}
              />
              {props.data.label}
            </div>
          </components.Option>
        );
      };
      const customStyles = {
        control: (provided) => ({
          ...provided,
          width:formValues.isLoading3 ? '80px' : '80px',
          height: '40px',
          border: 'none',
          boxShadow: 'none',
          background: 'transparent',
          display: 'flex',
          alignItems: 'center',
          }),
          dropdownIndicator: (provided) => ({
          ...provided,
          padding: '0 8px',
          }),
          Container:(provided)=> ({
           ...provided,
           top:'4px'
          }),
          indicatorSeparator: () => ({
          display: 'none',
          }),
          singleValue: (provided) => ({
          ...provided,
          display: 'flex',
          alignItems: 'center',
          }),
          valueContainer: (provided) => ({
          ...provided,
          padding: '0',
          display: 'flex',
          alignItems: 'center',
          }),
          input: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          }),
          placeholder: (provided) => ({
          ...provided,
          margin: '0',
          padding: '0',
          }),
          menu: (provided) => ({
          ...provided,
          minWidth: '300px',})  
      }
      const [islogged,setIslogged] = useState(false);
      const check = useCallback(()=>{
        const valid = (
          formValues.username !== '' && formValues.password !==''
        )
       setIslogged(valid);
      },[formValues.username,formValues.password])
       useEffect(() => {
        check();
      },[check]);
      const login = async(e) => {
        e.preventDefault();
        const gmailUsernamePattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let data = null;
        if (!formValues.username && !formValues.password) {
            setError((prevState)=>({
                ...prevState,
                usernameerror:'Required',passworderror:'Required'
            }));
            // setUsernameError('Required');
            // setpasswordError('Required');
            return false;
        } else if (!formValues.username) {
            setError((prevState)=>({
                ...prevState,
                usernameerror:'Required',passworderror:''
            }));
            // setUsernameError('Required');
            // setpasswordError('');
            return false;
        }
        else if (!formValues.password) {
            setError((prevState)=>({
                ...prevState,
                usernameerror:'',passworderror:'Required'
            }));
            // setpasswordError('Required');
            // setUsernameError('');
            return false;
        }
        else {
            // setUsernameError('');
            // setpasswordError('');
        }
        if (formValues.username && formValues.password) {
            if (/^\+?\d+$/.test(formValues.username)) {
                if (!formValues.mcc_id) {
                    setError((prevState)=>({
                        ...prevState,
                        usernameerror:'Select Your Country Again'
                    }));
                    // setUsernameError('Select Your Country Again');
                    return false;
                }
                 data = {
                    username: formValues.username,
                    password: formValues.password,
                    mcc_id: formValues.mcc_id,
                    device_type:'web'
                };
            } else if (gmailUsernamePattern.test(formValues.username)) {
                // Username is a valid Gmail address
                 data = {
                    username: formValues.username,
                    password: formValues.password,
                    device_type:'web'
                };
            } else {
                setError((prevState)=>({
                    ...prevState,
                    usernameerror:'Invalid Email Address'
                }));
                // setUsernameError('Invalid Email Address');
                return false;
            }  
            try {
                    setIsLoading4(true);
                    const response = await axiosInstance.post(loginUrl,data,);
                if (response.status === 200) {
                  const params = process_params();
                    let X_AuthUser = response.data.data.authuser;
                    // const redirectUrl = getQueryParameter('redirecturl');
                        if (params.redirecturl) {
                          showSuccessToast('Login successful. Welcome back!');
                          setTimeout(() => {
                          window.location.href = params.redirecturl
                        }, 2000);
                            // redirectToWithAuthUser(redirectUrl, X_AuthUser);
                    } else {
                      showSuccessToast('Login successful. Welcome back!');
                      navigate(`/success`);
                    }
                    setIsLoading4(false);
                    }
            } catch (error) {
              if(error.response.status === 409){
                const X_AuthUser = error.response.data.data.authuser;
                // const redirectUrl = getQueryParameter('redirecturl');
                const params = process_params();
                if(params.redirecturl){
                    // redirectToWithAuthUser(redirectUrl, X_AuthUser);
                    showSuccessToast('Login successful. Welcome back!');
                          setTimeout(() => {
                          window.location.href = params.redirecturl
                        }, 2000);
                }else{
                  showSuccessToast('Login successful. Welcome back!')
                  navigate(`/success`);
                    // makeAjaxCall();
                    // navigate(`/u/${X_AuthUser}/services`);
                }
                return false;
              }else if(error.response.status === 401){
                  const error_code = error.response.data.code;
                 if (error_code === 'auth_failed') {
                    setIsLoading4(false);
                    setError422(error.response.data.mobile_error);
                 }
              }else{  
              handleApiError(error,setIsLoading4,setError422,false,showErrorToast);
              }
            }
        }
      }
  return (
    <>
    <form action="#" className="login">
                                <h3 className="tit_24">Log in</h3>
                                <div className="row">
                                    <div className="col col_1">
                                        <div className="form_group">
                                            <label>Email address</label>
                                             {!formValues.showcountryinput ? (
                                            <input  type="email" value={formValues.username} ref={inputref} onChange={handleUsernameChange} className={`form_controls logininputsign ${formValues.username ? 'logininputsign-active' : ''}`} />
                                             ) : (
                                             <>
                                              <div className="mt-4" style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                                                <div style={{ position: 'absolute', left: 0, zIndex: 1,top:'5px' }}>
                                                <Select options={formValues.countryOptions} onChange={handleCountryChange} value={formValues.countryOptions.find(option => option.value === formValues.country_code)} components={{ SingleValue: customSingleValue, Option: customOption }} placeholder="" isLoading={formValues.isLoading3} styles={customStyles}/>
                                                </div>
                                                <input type="text" className={`form_controls logininputsign ${formValues.username ? 'logininputsign-active' : ''}`} id="mobile"  ref={mobileInputRef} style={{ paddingLeft: '90px', width: '100%' }}  value={formValues.username} name="mobile" onPaste={(e) => handlePaste(e)} onChange={handleUsernameChange}/>
                                                </div>
                                             </>
                                            )}
                                            {error.usernameerror && <div className="errors">{error.usernameerror}</div>}
                                        </div>
                                    </div>
                                    <div className="col col_1">
                                        <div className="form_group" style={{position : 'relative'}}>
                                            <label>Password</label>
                                            <input name='password' type={formValues.passwordshow ? 'text' : 'password'} value={formValues.password} onChange={handleInputChange} className={`form_controls logininputsign ${formValues.password ? 'logininputsign-active' : ''}`} />
                                            <p className="toggle-passwordss" onClick={passwordvisiblity} >
                                              {formValues.passwordshow ? (
                                                  <img src={blueeye} className='eye' style={{ width: '18px', height: 'auto' }} alt="Hide Password" />
                                              ) : (
                                                  <img src={blueeyeslash} className='eye' style={{ width: '18px', height: 'auto' }} alt="Show Password" />
                                              )}
                                              </p>
                                            {error.passworderror && <div className="errors">{error.passworderror}</div>}
                                        </div>
                                    </div>
                                    <div className="col col_1">
                                        {/* <div className="login_check">
                                            <div className="form_check">
                                                <input type="checkbox" />
                                                <label>Remember me</label>
                                            </div> */}
                                            <div className="login_check" ><Link to='/auth/forgot'>Forgot your password</Link></div>
                                        {/* </div> */}
                                    </div>
                                    <div className="col col_1">
                                    {error422 && <div className="errors">{error422}</div>}
                                        <button className={islogged ? 'signin_btn-active' : 'signin_btn'} disabled={!islogged} onClick={login}>Log in</button>
                                    </div>
                                </div>
                                <div className="line_color"></div>
                                <div className="form_padd">
                                    <h4 className="tit_20">Don't have an account?</h4>
                                    <button 
                                        className="form_fb" 
                                        onClick={() => setIsSignUp(true)}
                                    >
                                        Sign up
                                    </button>
                                </div>
                            </form>
    </>
  )
}

export default WithToast(Loginpage);