import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import { useState } from 'react';
import Login from './authpages/login';
import 'react-toastify/dist/ReactToastify.css';
import Playstore from './commoncomponents/playstore';
import { ToastContainer } from 'react-toastify';
import Forgotpage from './authpages/forgotpages/forgotpage';
import Loader from './loader';
function App() {
  const [isLoading4,setIsLoading4] = useState(false);
  return (
    <>
    {isLoading4 && <Loader/>}
    <ToastContainer/>
    <Router>
      <Routes>
      <Route exact path='/' element={<Navigate to="/login" replace />} />
        <Route  path = '/signup' element={<Login/>}/>
        <Route  path = '/login' element={<Login/>}/>
        <Route path='/auth/forgot' element={<Forgotpage setIsLoading4={setIsLoading4}/>}/>
        <Route path = '/success' element={<Playstore/>}/>
      </Routes>
    </Router>
    </>
  );
}

export default App;
