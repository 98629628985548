function domain()
{
    return process.env.REACT_APP_API_URL;
}
export function devandliveurl()
{
    return process.env.REACT_APP_DOMAIN_URL;
}
function domainstylish()
{
    const apiUrl = process.env.REACT_APP_API_URL;
    if (apiUrl) {
      return apiUrl.replace('/core', ''); // Remove only the '/core' part
    }
}
export function publicDomain()
{
    return process.env.REACT_APP_PUBLIC_URL;
}
export function countryselectapi()
{
    return domain() + "/general/country/list/all";
}
export function country_codeapi()
{
    return domain() + "/general/mcc/list/all";
}
export function signupapi()
{
    return domain() + "/auth/signup";
}
export function sidapi()
{
    return domain() + "/auth/sid";
}
export function resendotpapi()
{
    return domain() + '/auth/resend-otp';
}
export function signupverifyapi()
{
    return domain() + '/auth/verify-otp';
}
export function loginapi()
{
   return domain() + "/auth/login";
}
export function profileapi()
{
    return domain() + '/user/profile'
}
export function logoutallapi()
{
    return domain() + '/auth/logout/all';
}
export function forgotpasswordsendotpapi()
{
    return domain() + '/auth/forgot/password';
}
export function forgotpasswordotpverifyapi()
{
    return domain() + '/auth/forgot/password/verify-otp';
}
export function forgotresetpasswordapi()
{
    return domain() + '/auth/reset/password';
}
export function stylistapi()
{
   return domainstylish() + "/hairstyle/provider/stylist/details/create";
}
export function guestapi()
{
   return domainstylish() + "/hairstyle/user/customer/details/create";
}
